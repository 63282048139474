import { useEffect, useState } from "react";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import loginLogo from "../assets/images/loginLogo.svg";
// import googlePlusLogin from "../assets/images/googlePlusLogin.svg";
// import facebookLogin from "../assets/images/facebookLogin.svg";
import bigPurplePhoneLogo from "../assets/images/bigPurpleLogo-dark.svg";

import CustomPasswordInput from "../components/CustomPasswordInput";
import { signin } from "../redux/user";
import { changeLovedOne } from "../redux/lovedOne";
import { getDeviceToken } from "../utils/firebase";

import { login, updateVerify, getDataForVerification } from "../api/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { PhoneComponent } from "components/InputFields";
import * as Yup from "yup";
import messages from "utils/messages";
import { inputValidation } from "utils/validations";
import { useErrorHandler } from "react-error-boundary";
import InstallPWA from "components/InstallPWA";
import { setPassword, setProfile, setRole } from "utils/localStorage";

export default function LoginScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  console.log("params", params)

  const user = useSelector((state) => state.user);

  const [error, setError] = useState({ isError: false, msg: "" });
  const [device_token, setDeviceToken] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [login_cred, setLoginCred] = useState({
    phone: "",
    password: "",
    remember_me: false,
  });
  const handleError = useErrorHandler();
  const cookie_name = "bpp_cred";

  function getCookie(cookieName) {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
        cookie = cookie.trim();
        if (cookie.startsWith(cookieName + '=')) {
            return cookie.substring(cookieName.length + 1);
        }
    }
    return null;
}

  useEffect(async () => {

    console.log("check the token")
    let token = await getDeviceToken();

    console.log("token data", token)
    setDeviceToken(token ? token : "");
    // const myCookieValue = getCookie('myCookieName');
    const cookiesCred = getCookie(cookie_name);
    console.log("document", cookiesCred)
    if (cookiesCred) {
      console.log(
        "document.cookie.split(cookie_name)",
        cookiesCred
      );
      setLoginCred(JSON.parse(cookiesCred))
      // setLoginCred(JSON.parse(document.cookie.split("=")[1]));
      // return document.cookie.split(cookie_name)[1].split("; ")[0].substr(1);
    }

    if (params.string) {
      setLoading(true);

      getDataForVerification(params.string)
        .then((result) => {
          console.log("result :>> ", result);
          if (result.statusCode == 200) {
            setUserData(result.data);
          } else {
          }
        })
        .catch((error) => {
          setUserData({});
          console.log("error :>> ", error);
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (user.isAuthorized) {
        navigate("/");
      }
    }
  }, []);

  useEffect(async () => {
    if (userData) {
      console.log("userData :>> ", userData);
    }
    if (Object.keys(userData).length && user.isAuthorized) {
      if (userData.mobile == user.profile.mobile) {
        await updateUserVerify({ string: params.string });
      } else {
        if (Object.keys(userData).length) {
          toast(
            "Verification mobile number is different with your logged in mobile number",
            {
              type: "error",
            }
          );
          navigate("/");
        }
      }
    }
  }, [userData]);

  const handleSubmit = (data) => {

    console.log("login api hit", data, device_token)
    data.string = params ? params.string : "";
    data.mobile = data.phone;
    data.device_token = device_token
    if (data.remember_me) {
      // save the credentials in cookie
      let cookie_data = {
        phone: data.phone,
        password: data.password,
        remember_me: data.remember_me,
      };
      let cookieString = JSON.stringify(cookie_data);

      // localStorage.setItem('cookieData', JSON.stringify({ password: data.password }));
      // setPassword(data?.password)
      document.cookie = `${cookie_name}=${cookieString}; path=/`;
    } else {
      document.cookie = cookie_name + '="";-1; path=/';
    }
    // login(data)
    //   .then((res) => {
    //     console.log("res----->>>>>", res)
    //     // setPassword(data.password)
    //     // if (data.password === 'bigpurplephone@123') {
    //     //   setPassword(data.password)
    //     // }
    //     console.log("params.string", params.string)
    //     if (params.string) {
    //       updateUserVerify(data, res);
    //     } else {
    //       console.log("else block")
    //       dispatch(signin());
    //       if (res.data.data.self_managed) {
    //         navigate("/MenuScreen", { state: res.data.data });
    //       } else {
    //         let lovedOneList = res.data.data.lovedOneList;
    //         if (lovedOneList.length == 0) {
    //           navigate("/LovedOneList");
    //         } else {
    //           dispatch(changeLovedOne(lovedOneList[0]));
    //           navigate("/MenuScreen", { state: lovedOneList[0] });
    //         }
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     setError({
    //       msg: "",
    //       isError: false,
    //       serverError: "Invalid mobile or password",
    //     });
    //   });
    login(data)
      .then(async (res) => {
        setPassword(data.password)
        if (params.string) {
          updateUserVerify(data, res);
        } else {
          dispatch(signin());
          let lovedOneList = res.data.data.lovedOneList || [];
          if (lovedOneList.length === 0 && 'self_managed' in res.data.data) {
            setRole("lo")
            // Navigate directly to MenuScreen for self-managed LO
            navigate("/MenuScreen", { state: res.data.data });
          } else if (lovedOneList.length > 0) {
            dispatch(changeLovedOne(lovedOneList[0]));
            navigate("/MenuScreen", { state: lovedOneList[0] });
          } else {
            navigate("/LovedOneList");
          }
        }
        setTimeout(()=>{
          localStorage.removeItem("callingUrl");
        },3000)
      })
  };

  const updateUserVerify = async (data, res = false) => {
    await updateVerify(data)
      .then(() => {
        if (res) {
          console.log("data", data)
          dispatch(signin());
          let lovedOneList = res.data.data.lovedOneList;
          if (lovedOneList.length == 0) {
            navigate("/LovedOneList");
          } else {
            dispatch(changeLovedOne(lovedOneList[0]));
            navigate("/MenuScreen", { state: lovedOneList[0] });
          }
        } else {
          navigate("/LovedOneList");
        }
      })
      .catch((error) => {
        setError({
          msg: "",
          isError: false,
          serverError: "Invalid email or password",
        });
      });
  };

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required(messages.required.password),
    phone: Yup.string()
      .required(messages.required.phone)
      .test({
        name: "validator-custom-name",
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          return inputValidation(value, this);
        },
      })
      .test(
        "is-confirm",
        (d) => `Number Doesn't Match`,
        (value) => (params.string && value ? userData.mobile === value : true)
      ),
  });

  const formik = useFormik({
    initialValues: {
      ...login_cred,
      role: "pfa",
      device_token,
    },
    validationSchema: LoginSchema,

    onSubmit: (values) => {
      console.log("values :>> ", values);
      const castValues = LoginSchema.cast(values);
      handleSubmit(castValues);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <InstallPWA />
      <div className="mainPage loginPage">
        <div className="loginLogo loginfix">
          <img src={loginLogo} alt="" />
        </div>

        <div className="contentBody">
          <h1>
            <span>Welcome.</span>
            Sign in to proceed.
          </h1>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-3">
                  <PhoneComponent
                    formik={formik}
                    name={"phone"}
                    label={"Mobile No"}
                    placeholder={"Mobile"}
                    icon={"phone"}
                    type="text"
                    required
                  />
                </div>
                <div className="mb-3">
                  <CustomPasswordInput
                    formik={formik}
                    name="password"
                    label="Password"
                    required
                  />
                </div>
              </div>

              <div className="col-lg-12 mb-4">
                <input
                  type="checkbox"
                  id="remember_me"
                  name="remember_me"
                  className="form-check-input"
                  onChange={formik.handleChange}
                  checked={formik.values.remember_me}
                  value={formik.values.remember_me}
                />{" "}
                Remember Me
                <NavLink to="/ForgotPass" className="aNormalT">
                  Forgot Password?
                </NavLink>
                <div className="">
                  <small className="fs12">
                    <span className="pe-2">
                      <b>*=Required fields</b>
                    </span>
                  </small>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <button type="submit" className="btn btn-primary w-100 mb-3">
                  Sign in
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="bigpurplebottom text-center pb-2 bgnone">
          <img src={bigPurplePhoneLogo} alt="" />
        </div>
      </div>
    </>
  );
}
